import { Component, HostListener } from '@angular/core';
import { GeneralService } from './_core/services/general.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {

  constructor(
    private generalService: GeneralService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    const oldOrientation = this.generalService.pageSize.landscape;
    this.generalService.pageSize.height = event.target.innerHeight;
    this.generalService.pageSize.width = event.target.innerWidth;
    this.generalService.pageSize.landscape = window.innerWidth > window.innerHeight ? true : false;
    if (oldOrientation !== this.generalService.pageSize.landscape) {
      location.reload();
    }
  }
}
