import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { FileSaverService } from 'ngx-filesaver';

declare var gapi: any;

class GoogleReservation {
  allDay: boolean;
  description: string;
  endDate: string;
  startDate: string;
  text: string;
}

@Injectable({ providedIn: 'root' })
export class FirebaseService {
  user$: Observable<firebase.User>;
  signedIn = false;
  calendarItems: any[];
  lastReservation: GoogleReservation;

  constructor(
    public afAuth: AngularFireAuth,
    private toastr: ToastrService,
    private fileSaverService: FileSaverService) {
    this.initClient();
    this.user$ = afAuth.authState;
  }

  initClient() {
    console.log(environment.clientBaseURL)
    gapi.load('client', () => {
      gapi.client.init({
        apiKey: environment.firebase.apiKey,
        clientId: '182621452859-drm1g2om59nhittgf8rh3fg0is6e2lqq.apps.googleusercontent.com', // PRODUCTION CLIENTID
        discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
        scope: 'https://www.googleapis.com/auth/calendar',
        // ux_mode: 'redirect',
        // redirect_uri: environment.googleRedirectURL
      }).then(() => {
        this.updateStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
      });
      gapi.client.load('calendar', 'v3', (res: any) => {

      }, (error: any) => {
        console.error(error);
      }, () => {

      });
    });
  }

  updateStatus(value: boolean) {
    this.signedIn = value;
  }

  async login() {
    this.signedIn = true;
    const googleAuth = gapi.auth2.getAuthInstance();
    const googleUser = await googleAuth.signIn();
    const token = googleUser.getAuthResponse().id_token;
    const credential = auth.GoogleAuthProvider.credential(token);
    await this.afAuth.auth.signInAndRetrieveDataWithCredential(credential);
    this.signedIn = gapi.auth2.getAuthInstance().isSignedIn.get();
  }

  async logout() {
    this.signedIn = false;
    this.afAuth.auth.signOut().then(function() {
      // window.open("https://mail.google.com/mail/u/0/?logout&hl=en");
    }, function(error) {
      console.error('ERROR:', error);
    });
    this.calendarItems = null;
    localStorage.removeItem('https://content.googleapis.com');
    localStorage.removeItem('https://accounts.google.com');
    sessionStorage.removeItem('https://content.googleapis.com');
    sessionStorage.removeItem('https://accounts.google.com');
    await gapi.auth2.getAuthInstance().signOut();
    this.signedIn = gapi.auth2.getAuthInstance().isSignedIn.get();
  }

  async getCalendar() {
    try {
      const events = await gapi.client.calendar.events.list({
        calendarId: 'primary',
        timeMin: new Date().toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 10,
        orderBy: 'startTime'
      });
      this.calendarItems = events.result.items;
    } catch (error) {
      if (error.result.error.message === 'Login Required') {
        this.toastr.error('You must be logged in to retrieve your Google calendar.', 'Login Required');
      } else {
        this.toastr.error('We don\'t know what went wrong, please try again.', 'Unknown error');
      }
    }
  }

  async insertEvent(reservation: GoogleReservation) {
    console.log('data',reservation)
    this.lastReservation = reservation;
    try {
      if (reservation.allDay === true) {
        const insert = await gapi.client.calendar.events.insert(
          {
            'calendarId': 'primary',
            start: {
              date: this.formatDate(new Date(reservation.startDate)),
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            end: {
              date: this.formatDate(new Date(reservation.endDate)),
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            summary: reservation.text,
            description: reservation.description
        });
      } else {
        const insert = await gapi.client.calendar.events.insert(
          {
            'calendarId': 'primary',
            start: {
              dateTime: new Date(reservation.startDate),
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            end: {
              dateTime: new Date(reservation.endDate),
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            summary: reservation.text,
            description: reservation.description
        });
      }
      await this.getCalendar();
      this.toastr.success('Your reservation was added to your Google Calendar!', 'Success!');
      this.lastReservation = reservation;
      return 'Success';
    } catch (error) {
      if (error.result.error.message === 'Login Required') {
        this.toastr.error('You must be logged in to add the event to your Google calendar.', 'Login Required');
        return 'Login';
      } else {
        this.toastr.error('We don\'t know what went wrong, please try again.', 'Unknown error');
        return 'Error';
      }
    }
  }

  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }
    return [year, month, day].join('-');
  }

  createICS(reservation) {
    //console.log(reservation)
    let startDate:string = reservation.startDate.toString();
    let endDate:string = reservation.endDate.toString();
    const fileName = reservation.text + '.ics';
    // tslint:disable-next-line: max-line-length
    const blobText = `BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\nDTSTART:` + startDate.replace(/(-)*(:)*(\.000)*(Z)*/g, '') + `\nDTEND:` + endDate.replace(/(-)*(:)*(\.000)*(Z)*/g, '') + `\nSUMMARY:` + reservation.text + `\nDESCRIPTION:` + reservation.description + `\nEND:VEVENT\nEND:VCALENDAR`;
    console.log(blobText);
    const fileType = this.fileSaverService.genType(fileName);
    const txtBlob = new Blob([blobText], { type: fileType });
    this.fileSaverService.save(txtBlob, fileName);
    //this.lastReservation = null;
  }

}
