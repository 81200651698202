import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AdminService } from './services/admin.service';
import { UserService } from './services/user.service';
import { AuthenticationService } from './services/authentication.service';
import { SessionService } from '../session/session.service';
import { ProjectsService } from './services/projects.service';
import { TicketService } from './services/ticket.service';
import { AssetService } from './services/asset.service';
import { ODataService } from './services/odata.service';
import { OrganizationsService } from './services/organizations.service';
import { ManagementService } from './services/management.service';
import { GRootService } from './services/groot.service';
import { AuthenticationInterceptor } from './http-interceptors/authentication.interceptor';
import { GeneralService } from './services/general.service';
import { DashboardService } from './services/dashboard.service';
import { ReportingService } from './services/reporting.service';
import { UploaderService } from './services/uploader.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  exports: [],
  providers: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        AdminService,
        UserService,
        SessionService,
        ProjectsService,
        OrganizationsService,
        AuthenticationService,
        TicketService,
        AssetService,
        ODataService,
        ManagementService,
        GRootService,
        GeneralService,
        DashboardService,
        ReportingService,
        UploaderService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthenticationInterceptor,
          multi: true
        }
      ]
    };
  }
}
