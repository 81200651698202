import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FormGroup } from '@angular/forms';
import { Reservation } from '../models/Asset';

interface NewCoverage {
  ID: number;
  AssetID: number;
  UID: string;
  CoverageType: string;
  CoverageProvider: string;
  CoverageNumber: string;
  ContactEmail: string;
  ContactPhone: string;
  CoverageCost: number;
  CoverageDuration: string;
  CoverageTerm: string;
  CoverageExpiration: Date;
  CoverageDescription: string;
  CreatedDT: Date;
  CreatedBy: string;
}

@Injectable()
export class AssetService {


  constructor(
    private httpclient: HttpClient) {

  }

  getAllAssets(): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Asset/getAllAssets');
  }

  getAssetByID(assetID): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Asset/GetAssetByID?AssetID=' + assetID);
  }

  getLocationByID(locationID): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Asset/GetLocationByID?LocationID=' + locationID);
  }

  getAssetNotes(assetID): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Asset/getAllAssets');
  }

  getL1BldgSystems(): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Asset/GetL1BldgSystems');
  }

  getL2BldgSystems(parentL1): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Asset/GetL2BldgSystems?ParentL1ID=' + parentL1);
  }

  getL3BldgSystems(parentL2): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Asset/GetL3BldgSystems?ParentL2ID=' + parentL2);
  }

  getL4BldgSystems(parentL3): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Asset/GetL4BldgSystems?ParentL3ID=' + parentL3);
  }

  getUnitOfMeasure(): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Asset/getUnitOfMeasure');
  }

  getCommissioningTypes(): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Asset/GetCommissioningTypes');
  }

  getAllAssetLocations(): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Asset/getAllAssetLocations');
  }

  getEquipmentTypes(ProjectID): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Asset/getEquipmentTypes?ProjectID=' + ProjectID);
  }

  createNewAssetEquipment(NewAssetFormValue: FormGroup): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/Asset/CreateNewAssetEquipment', NewAssetFormValue);
  }

  createNewAssetLocation(NewLocationFormValue: FormGroup): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/Asset/CreateNewAssetLocation', NewLocationFormValue);
  }

  createNewReservation(assetID: string, type: string, Res: Reservation): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/Asset/createNewReservation?assetID=' + assetID + '&type=' + type, Res);
  }

  GetReservations(assetID: number, type: string): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Asset/GetReservations?assetID=' + assetID + '&type=' + type);
  }

  UpdateReservation(type: string, Res: Reservation): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/Asset/UpdateReservation?type=' + type, Res);
  }

  DeleteReservation(assetID: string, type: string, Res: Reservation): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/Asset/DeleteReservation?assetID=' + assetID + '&type=' + type, Res);
  }

  CreateCoverage(newCoverage: NewCoverage): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/Asset/CreateCoverage', newCoverage);
  }

  LoadAssetCoverage(assetID: string): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Asset/LoadAssetCoverage?assetID=' + assetID);
  }

  GetActiveCoverage(assetID: string): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Asset/GetActiveCoverage?assetID=' + assetID);
  }

  GetExpiredCoverage(assetID: string): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Asset/GetExpiredCoverage?assetID=' + assetID);
  }
  GetPendingReservations(assetID: number, type: string): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Asset/GetPendingReservations?assetID=' + assetID +'&type=' +type);
  }
  ValidateReservation(type: string, Res: Reservation): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/Asset/ValidateReservation?type=' + type, Res);
  }
  GetPendingReservation(ID: number, type: string): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Asset/GetPendingReservation?ID=' + ID +'&type=' +type);
  }
  UpdatePendingReservation(type: string, Res: Reservation): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/Asset/UpdatePendingReservation?type='+type, Res);
  }
  getAssetAttachments(assetID: number): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/aws/GetAssetAttachments?AssetID=' + assetID, null);
  }
  getAssetAttachmentsFiles(assetID: number): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/aws/GetAssetAttachmentsDocuments?AssetID=' + assetID, null);
  }
  getLocationAttachments(assetID: number): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/aws/GetLocationAttachments?AssetID=' + assetID, null);
  }
  getLocationAttachmentsFiles(assetID: number): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/aws/GetLocationAttachmentsDocuments?AssetID=' + assetID, null);
  }
  DeleteAsset(assetID: number, type: string): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/Asset/DeleteAsset?AssetID='+ assetID + '&type='+type,'');
  }
}
