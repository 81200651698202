import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable()
export class ManagementService {

  constructor(
    private httpclient: HttpClient) {

  }

  CategoryAssignment(CategoriesModel): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/Management/CategoryAssignment', CategoriesModel);
  }

  AddNewUser(Id: string, ProjectID: number): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/Management/AddNewUser?Id=' + Id + '&projectID=' + ProjectID, '');
  }
  categoryReassignment(data:any): Observable<any>{
    return this.httpclient.post(environment.apiBaseURL + 'api/Management/CategoryReAssignment', data);
  }
}
