import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauth-layout',
  templateUrl: './unauth-layout.component.html',
  styleUrls: ['./unauth-layout.component.scss']
})
export class UnauthLayoutComponent implements OnInit {
  mobileTF: boolean;

  constructor() { }

  ngOnInit() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobi/i.test(navigator.userAgent)) {
      this.mobileTF = true;
    } else {
      this.mobileTF = false;
    }
  }

}
