import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GRootService } from './groot.service';
import { GeneralService } from './general.service';

@Injectable()
export class ProjectsService {
  constructor(
    private httpclient: HttpClient,
    private gRootService: GRootService,
    private generalService: GeneralService) {

  }

  getProjects(): Observable<any> {
    if (this.generalService.roleMatch(['Root'])) {
      return this.gRootService.getAllProjects();
    } else {
      return this.httpclient.get(environment.apiBaseURL + 'api/Projects/getAssignedProjects');
    }
  }

  getAssignedProjects(): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Projects/getAssignedProjects');
  }

  getAssignedProjectsInOrg(): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Projects/getAssignedProjectsInOrg');
  }

  getSingleProject(id: string) {
    const params: HttpParams = new HttpParams().set('id', id);
    return this.httpclient.get(environment.apiBaseURL + 'api/Projects/getSingleProject', { params: params });
  }

  getProjectUsers(id: string): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Projects/getProjectUsers?id=' + id);
  }

  getProjectAssignments(id: string): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Projects/getProjectAssignments?id=' + id);
  }
  getProjectContacts(id: string): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Projects/getProjectContacts?id=' + id);
  }

  getReservations(projectID: number): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Projects/getReservations?projectID=' + projectID);
  }
}
