import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    var url: string = state.url;
    if (this.authService.isLoggedIn()) {
      return true;
    } else {
      // state.url= state.url.substr(1);
      // url= 'u/Login?returnTo=' + state.url;
      // console.log(url);
      this.authService.storeRedirectURL(url);
      console.error('AuthGuard not logged in: ', this.authService.redirectURL) ;
      this.authService.handleUnauthenticated();
      return false;
    }
  }
}
