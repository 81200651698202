import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FormGroup } from '@angular/forms';
import { ChangePasswordModel } from '../models/ChangePasswordModel';
import { GRootService } from './groot.service';
import { GeneralService } from './general.service';


@Injectable()
export class AdminService {


  constructor(
    private httpclient: HttpClient,
    private gRootService: GRootService,
    private generalService: GeneralService) { }

  createNewUser(CreateUserFormValue: FormGroup): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/admin/CreateNewUser', CreateUserFormValue);
  }

  getAllUsersInOrg(OrgID: string): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Admin/getAllUsersInOrg?OrgID=' + OrgID);
  }

  getAssignedRolesByID (Id: string): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Admin/GetAssignedRolesByID?UserID=' + Id);
  }

  assignRoleToUser (RoleModel): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/Admin/AssignRoleToUser', RoleModel);
  }

  modifyUserRole(userID: string, roleInfo: { roleID: string, roleType: string , roleAddTF: boolean}) {
    return this.httpclient.post(environment.apiBaseURL + 'api/Admin/modifyUserRoles?UserID=' + userID, roleInfo);
  }

  getUserByID(userID: string): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Admin/getUserByID?Id=' + userID);
  }

  adminUpdateProfile(AdminUpdateProfile): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/Admin/AdminUpdateProfile', AdminUpdateProfile);
  }

  getBaseRoles(): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Admin/getBaseRoles');
  }

  getChildRoles(): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Admin/getChildRoles');
  }

  adminForgotPassword (email: string): Observable<any> {
    return  this.httpclient.get(environment.apiBaseURL + 'api/admin/AdminForgotPassword?email=' + email);
  }

  adminChangePassword(resetPassModel: ChangePasswordModel): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/admin/AdminChangePassword', resetPassModel);
  }

  getSingleOrganization(id: string): Observable<any> {
    const params: HttpParams = new HttpParams().set('id', id);
    return this.httpclient.get(environment.apiBaseURL + 'api/Admin/getSingleOrganizaiton', { params: params });
  }

  getOrganizationAssignments(orgId: string): Observable<any> {
    if (this.generalService.roleMatch(['Root'])) {
      return this.gRootService.getOrganizationAssignments(orgId);
    } else {
      return this.httpclient.get(environment.apiBaseURL + 'api/Admin/GetOrganizationAssignments');
    }
  }
  submitOrganization(OrgFormValue: FormGroup): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/Admin/submitOrganization', OrgFormValue, { observe: 'response' });
  }
  submitProject(ProjSetupFormValue: FormGroup): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/Admin/submitProject', ProjSetupFormValue);
  }

  createAssignNewUser(userToCreate: FormGroup) {
    return this.httpclient.post(environment.apiBaseURL + 'api/Admin/createAssignNewUser', userToCreate);
  }

  assignUserToProject(projectAsssignmentForm: FormGroup) {
    return this.httpclient.post(environment.apiBaseURL + 'api/Admin/assignUserToProject', projectAsssignmentForm, { observe: 'response' });
  }

  getAssignedProjectsInOrg(): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Admin/getAssignedProjectsInOrg');
  }

  getAllTicketsInOrg(): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Admin/getAllTicketsInOrg');
  }
  getAllOrganizations(): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/User/getAllOrganizations');
  }
  getLeasingTickets(): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/User/GetLeasingTickets');
  }
}
