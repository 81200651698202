export class AttachmentsModel {
    TicketID: number;
    AttachmentUID: string;
    FileName: string;
    FileExtension: string;
    Rotation: number;
    CreatedDT: Date;
    UserID: string;
}

export class UploaderEvent {
    AssetID:number;
    TicketID: number;
    ID:number;
    UserID: string;
    Result: boolean;
    Source: string;
}
export class ReassignEvent {
    ProjectID: number;
    UserName: string;
    UserID: string;
    TicketCategoryID: number;
    Result: boolean;
}
