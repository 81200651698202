import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FormGroup } from '@angular/forms';
import { AdminService } from './admin.service';
import { GRootService } from './groot.service';
import { GeneralService } from './general.service';


@Injectable()
export class OrganizationsService {
  constructor(
    private httpclient: HttpClient,
    private adminService: AdminService,
    private gRootService: GRootService,
    private generalService: GeneralService) {

  }

  submitOrganization(OrgFormValue: FormGroup): Observable<any> {
    if (this.generalService.roleMatch(['Root'])) {
      return this.gRootService.submitOrganization(OrgFormValue);
    } else if (this.generalService.roleMatch(['Admin'])) {
      return this.adminService.submitOrganization(OrgFormValue);
    }
  }

  getOrganizationAssignments(id: string): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Organizations/getOrganizationAssignments?id=' + id);
  }

  updateOwnerTF(updateData: any): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/Organizations/updateOwnerTF', updateData);
  }

  unAssignUserFromOrg(data: any): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/Organizations/unAssignUserFromOrg', data);
  }
}
