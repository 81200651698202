import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { UnauthLayoutComponent } from './layouts/unauth-layout/unauth-layout.component';
import { AuthGuard } from './_core/route-guards/auth.guard';


const routes: Routes = [
  {
    path: 'u',
    component: UnauthLayoutComponent,
    loadChildren: './session/session.module#SessionModule'
  },
  {
    path: '',
    component: AuthLayoutComponent,

    canActivate: [AuthGuard],
    loadChildren: './authenticated/authenticated.module#AuthenticatedModule'
  },
  {
    path: '**',
    redirectTo: 'u/Login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
