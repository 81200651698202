import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FormGroup } from '@angular/forms';
import { GRootService } from './groot.service';
import { AdminService } from './admin.service';
import { GeneralService } from './general.service';

@Injectable()
export class TicketService {
  constructor(
    private httpClient: HttpClient,
    private gRootService: GRootService,
    private adminService: AdminService,
    private generalService: GeneralService) {

  }

  jsonTypeHeader = new HttpHeaders().set('Content-Type', 'application/json');

  getTickets(): Observable<any> {
    if (this.generalService.roleMatch(['Root'])) {
      return this.gRootService.getAllTickets();
    } else if (this.generalService.roleMatch(['Admin'])) {
      return this.getTickets();
    }
    else if (this.generalService.roleMatch(['View All Tickets'])) {
      return this.getTickets();
    } else if (this.generalService.roleMatch(['View Organization Tickets'])) {
      return this.getOrgTickets();
    }
    // else if (this.generalService.roleMatch(['Manage Renters'])) {
    //   return this.adminService.getLeasingTickets();
    // }
    else {
      return this.httpClient.get(environment.apiBaseURL + 'api/Ticket/getUserTickets');
    }
  }

  getTicketsByOptions(loadOptions: any): Observable<any> {
    if (this.generalService.roleMatch(['Root'])) {
      return this.gRootService.getTicketsByOptions(loadOptions);
    } else if (this.generalService.roleMatch(['Admin'])) {
      return this.getAdminTicketsByOptions(loadOptions);
    }
    else if (this.generalService.roleMatch(['View All Tickets'])) {
      return this.getAdminTicketsByOptions(loadOptions);
    } else if (this.generalService.roleMatch(['View Organization Tickets'])) {
      return this.getOrgTicketsByOptions(loadOptions);
    }
    // else if (this.generalService.roleMatch(['Manage Renters'])) {
    //   return this.adminService.getLeasingTickets();
    // }
    else {
      return this.getUserTicketsByOptions(loadOptions);
    }
  }

  getTicketsByUserID(id: string): Observable<any> {
    return this.httpClient.get(environment.apiBaseURL + 'api/Ticket/getUserTickets?UserID=' + id);
  }

  getTicket(id: string): Observable<any> {
    return this.httpClient.get(environment.apiBaseURL + 'api/Ticket/getTicket?id=' + id);
  }

  getAllTickets(): Observable<any> {
    return this.httpClient.get(environment.apiBaseURL + 'api/Ticket/getAllTickets');
  }

  getAllTicketsByPage(pageSize: number, pageNumber: number): Observable<any> {
    return this.httpClient.get(environment.apiBaseURL + `api/Ticket/getAllTicketsByPage?pageSize=${ pageSize }&pageNumber=${ pageNumber }`);
  }

  getAdminTicketsByOptions(loadOptions: any) {
    return this.httpClient.post(environment.apiBaseURL + 'api/Ticket/getTicketsByOptions', loadOptions);
  }

  getAssignedTicketCategories(proj_id): Observable<any> {
    return this.httpClient.get(environment.apiBaseURL + 'api/Ticket/getAssignedTicketCategories?proj_id=' + proj_id);
  }

  getAllTicketCategories(proj_id): Observable<any> {
    return this.httpClient.get(environment.apiBaseURL + 'api/Ticket/getAllTicketCategories?proj_id=' + proj_id);
  }

  getTicketStatuses(): Observable<any> {
    return this.httpClient.get(environment.apiBaseURL + 'api/Ticket/getTicketStatuses');
  }

  getTicketWarranties(projID: string,): Observable<any> {
    return this.httpClient.get(environment.apiBaseURL + 'api/Ticket/getTicketWarranties?projID=' + projID);
  }

  getTicketUsers(id: string): Observable<any> {
    return this.httpClient.get(environment.apiBaseURL + 'api/Ticket/GetTicketUsers?id=' + id);
  }
  getTicketWorkers(id: number): Observable<any> {
    return this.httpClient.get(environment.apiBaseURL + 'api/Ticket/GetTicketWorkers?id=' + id);
  }

  getTicketHistory(id: string): Observable<any> {
    return this.httpClient.get(environment.apiBaseURL + 'api/Ticket/getTicketHistory?id=' + id);
  }

  getTicketTime(id: string): Observable<any> {
    return this.httpClient.get(environment.apiBaseURL + 'api/Ticket/getTicketTime?id=' + id);
  }

  getTicketAssignments(id: string): Observable<any> {
    return this.httpClient.get(environment.apiBaseURL + 'api/Ticket/GetUserTickets?UserID=' + id);
  }

  getTicketNotes(id: string): Observable<any> {
    return this.httpClient.get(environment.apiBaseURL + 'api/Ticket/getTicketNotes?id=' + id);
  }

  getUserTicketsByOptions(loadOptions: any) {
    return this.httpClient.post(environment.apiBaseURL + 'api/Ticket/getUserTicketsByOptions', loadOptions);
  }

  submitNewTicket(NewTicketFormValue: FormGroup): Observable<any> {
    return this.httpClient.post(environment.apiBaseURL + 'api/Ticket/newTicket', NewTicketFormValue);
  }

  submitNewTicketNote(NewNote): Observable<any> {
    return this.httpClient.post(environment.apiBaseURL + 'api/Ticket/newTicketNote', NewNote);
  }

  updateTicketTopForm(id: string, userID: string, UpdateTicketTopForm: FormGroup): Observable<any> {
    return this.httpClient.post(environment.apiBaseURL + 'api/Ticket/updateTicketTop?id=' + id + '&UserID=' + userID, UpdateTicketTopForm);
  }

  updateTicketStatus(id: number, updateInfo: any): Observable<any> {
    return this.httpClient.post(environment.apiBaseURL + 'api/Ticket/updateTicketStatus?id=' + id, updateInfo);
  }

  updateTicketWorker(id: number, updateInfo: any): Observable<any> {
    return this.httpClient.post(environment.apiBaseURL + 'api/Ticket/updateTicketWorker?id=' + id, updateInfo);
  }
  removeTicketWorker(id: number, updateInfo: any): Observable<any> {
    return this.httpClient.post(environment.apiBaseURL + 'api/Ticket/removeTicketWorker?id=' + id, updateInfo);
  }

  updateTicketBottomForm(id: string, UpdateTicketBottomForm: FormGroup): Observable<any> {
    return this.httpClient.post(environment.apiBaseURL + 'api/Ticket/updateTicketBottom?id=' + id, UpdateTicketBottomForm);
  }

  rotateAttachment(id: number, rotation: number): Observable<any> {
    return this.httpClient.get(environment.apiBaseURL + 'api/Ticket/rotateAttachment?id=' + id + '&rotation=' + rotation);
  }
  deleteAttachment(id: string): Observable<any> {
    return this.httpClient.get(environment.apiBaseURL + 'api/aws/DeleteAttachment?attachmentID=' + id);
  }

  submitTicketTime(id: string, SubmitTicketTimeForm: FormGroup): Observable<any> {
    return this.httpClient.post(environment.apiBaseURL + 'api/Ticket/submitTicketTime?id=' + id, SubmitTicketTimeForm);
  }

  getAttachments(ticketId: number): Observable<any> {
    return this.httpClient.post(environment.apiBaseURL + 'api/aws/GetAttachments?TicketID=' + ticketId, null);
  }
  getAttachmentsFiles(ticketId: number): Observable<any> {
    return this.httpClient.post(environment.apiBaseURL + 'api/aws/GetAttachmentsFiles?TicketID=' + ticketId, null);
  }

  deleteTicket(id: string): Observable<any> {
    return this.httpClient.post(environment.apiBaseURL + 'api/Ticket/deleteTicket?id=' + id, null);
  }

  setPrivateCategory(ticketCategoryID: number, projectID: number, isPrivate: boolean): Observable<any> {
    // tslint:disable-next-line: max-line-length
    return this.httpClient.post(environment.apiBaseURL + 'api/Ticket/setPrivateCategory?TicketCategoryID=' + ticketCategoryID + '&ProjectID=' + projectID + '&IsPrivate=' + isPrivate, null);
  }

  getTicketOwnerDropdown(ProjectID: number): Observable<any> {
    return this.httpClient.get(environment.apiBaseURL + 'api/Ticket/getTicketOwnerDropdown?ProjectID=' + ProjectID);
  }
  getAllAssets_vw(AssetType: string, ProjectID: number): Observable<any> {
    return this.httpClient.get(environment.apiBaseURL + 'api/Ticket/getAllAssets?AssetType=' + AssetType + '&ProjectID=' + ProjectID);
  }
  getLocationTicketHistory(AssetID: number): Observable<any> {
    return this.httpClient.get(environment.apiBaseURL + 'api/Ticket/getLocationTicketHistory?AssetID=' + AssetID);
  }
  getEquipmentTicketHistory(AssetID: number): Observable<any> {
    return this.httpClient.get(environment.apiBaseURL + 'api/Ticket/getEquipmentTicketHistory?AssetID=' + AssetID);
  }
  getOrgTickets(): Observable<any> {
    return this.httpClient.get(environment.apiBaseURL + 'api/Ticket/getOrgTickets');
  }
  getOrgTicketsByOptions(loadOptions: any): Observable<any> {
    return this.httpClient.post(environment.apiBaseURL + 'api/Ticket/getOrgTicketsByOptions', loadOptions);
  }
}
