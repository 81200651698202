import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FormGroup } from '@angular/forms';

@Injectable()
export class GRootService {
  constructor(
    private httpclient: HttpClient) {

  }

  organizationUserAssignment(orgID: number, userID: string): Observable<any> {
    const params: HttpParams = new HttpParams().set('orgID', orgID.toString()).append('userID', userID);
    return this.httpclient.post(environment.apiBaseURL + 'api/GRoot/OrganizationUserAssignment',
      {
        'orgID': orgID,
        'userID': userID
      });
  }

  getAllOrganizations(): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/GRoot/GetAllOrganizations');
  }

  getSingleOrganization(id: string) {
    const params: HttpParams = new HttpParams().set('id', id);
    return this.httpclient.get(environment.apiBaseURL + 'api/GRoot/getSingleOrganizaiton', { params: params });
  }
  submitOrganization(OrgFormValue: FormGroup): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/GRoot/submitOrganization', OrgFormValue, { observe: 'response' });
  }

  getOrganizationAssignments(id: string): Observable<any> {
    const params: HttpParams = new HttpParams().set('id', id);
    return this.httpclient.get(environment.apiBaseURL + 'api/GRoot/GetOrganizationAssignments', { params: params });
  }

  getAllUsers(): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/GRoot/getAllUsers');
  }

  getAllProjects(): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/GRoot/getAllProjects');
  }

  getSingleProject(id: string) {
    //const params: HttpParams = new HttpParams().set('id', id);
    return this.httpclient.get(environment.apiBaseURL + 'api/GRoot/getSingleProject?id=' + id);
  }

  submitProject(ProjSetupFormValue: FormGroup): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/GRoot/submitProject', ProjSetupFormValue);
  }

  getAllTickets(): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/GRoot/getAllTickets');
  }

  getTicketsByOptions(loadOptions: any): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/GRoot/getTicketsByOptions', loadOptions);
  }

  DeleteTicket(ticketID: number): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/GRoot/DeleteTicket?TicketID=' + ticketID, '');
  }
  DeleteUser(id: string): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/GRoot/DeleteUser?UserID=' + id, '');
  }
  RemoveOrg(ProjectID: number): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/GRoot/RemoveOrg?ProjectID=' + ProjectID, '');
  }
  GetUserByEmail(email: string): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/GRoot/GetUserByEmail?email=' + email);
  }
  createAssignNewUser(userToCreate: FormGroup) {
    return this.httpclient.post(environment.apiBaseURL + 'api/GRoot/createAssignNewUser', userToCreate);
  }
}
