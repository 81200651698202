import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthenticationService } from '../../_core/services/authentication.service';
import { UserService } from '../../_core/services/user.service';
import { Router } from '@angular/router';
import { MenuItems } from './auth-menu-items';
import { FirebaseService } from 'src/app/_core/services/firebase.service';
import { GeneralService } from 'src/app/_core/services/general.service';
import { LoaderService } from '../../loader.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  providers: [UserService, MenuItems]
})

export class AuthLayoutComponent implements OnInit, AfterViewInit {
  userInfo: any;
  NavLinks = [];
  NavLinksLength: number;
  permission = 10;
  testPermissions = [10, 20];
  roles: any;
  pageLoaded = false;
  uploading: any;

  constructor(
    private authService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    public menuItems: MenuItems,
    public firebase: FirebaseService,
    public generalService: GeneralService,
    public loaderService: LoaderService) {
    this.initiateSidebar();
    // this.listenToAuthService();
  }

  ngOnInit() {
    this.authService.hasTokenRefreshed.subscribe((res) => {
      this.initiateSidebar();
    }, (error) => {
      console.error(error);
    }, () => {

    });
    this.loaderService.isUploading.subscribe((v) => {
      // console.log('uploadarray',v);
      this.uploading = v;

    });

  }

  ngAfterViewInit() {
    let topNav: any;
    switch (this.router.url.split('/')[1]) {
      case ('AM'):
        topNav = document.getElementById('Asset Management');
        break;
      case ('Admin'):
        topNav = document.getElementById('Admin');
        break;
      default:
        topNav = null;
        break;
    }
    if (topNav != null) {
      topNav.classList.remove('topNav');
      topNav.classList.add('topNavActive');
      const subNav = document.getElementById(this.router.url.split('/')[2]);
      if (subNav != null) {
        subNav.classList.remove('subNav');
        subNav.classList.add('subNavActive');
      }
    }
    this.pageLoaded = true;
  }

  initiateSidebar() {
    this.userInfo = this.userService.getUserData();
    this.roles = this.userService.getRole();
    this.NavLinks = this.menuItems.getNavItems();
    this.NavLinksLength = this.NavLinks.length;
    if (!this.generalService.roleMatch(['Admin', 'Root', 'SysAdmin'])) {
      for (let i = 0; i < this.NavLinksLength; i++) {
        if (this.NavLinks[i].permission !== '' && this.roles.indexOf(this.NavLinks[i].permission, 0) === -1) {
          this.NavLinks.splice(i, 1);
          this.NavLinksLength -= 1;
          i -= 1;
        } else {
          const subNavLinks = this.NavLinks[i].children;
          let subNavLinksLength = subNavLinks.length;
          for (let x = 0; x < subNavLinksLength; x++) {
            if (subNavLinks[x].permission !== '' && this.roles.indexOf(subNavLinks[x].permission, 0) === -1) {
              subNavLinks.splice(x, 1);
              subNavLinksLength -= 1;
              x -= 1;
            }
          }
        }
      }
    }
  }

  listenToAuthService() {
    this.authService.hasTokenRefreshed.subscribe((res) => {
      this.initiateSidebar();
    }, (error) => {
      console.error(error);
    });
  }

  signOut() {
    this.authService.logout();
  }

  toggleSnav() {
    const toggle = document.getElementsByClassName('toggleSlide');
    const collapse = document.getElementsByClassName('slideCollapse');
    const content = document.getElementById('content');
    if (!this.generalService.navBarExpanded) {
      for (let i = 0; i < toggle.length; i++) { // TOGGLE
        toggle[i].classList.add('slideIn');
        toggle[i].classList.remove('slideOut');
      }
      for (let i = 0; i < collapse.length; i++) { // COLLAPSE
        collapse[i].classList.add('collapseOut');
        collapse[i].classList.remove('collapseIn');
      }
      content.classList.add('shiftRight');
    } else {
      for (let i = 0; i < toggle.length; i++) { // TOGGLE
        toggle[i].classList.add('slideOut');
        toggle[i].classList.remove('slideIn');
      }
      for (let i = 0; i < collapse.length; i++) { // COLLAPSE
        collapse[i].classList.add('collapseIn');
        collapse[i].classList.remove('collapseOut');
      }
      content.classList.remove('shiftRight');
    }
  }

  async checkSlide(fireFunction: string) {
    if (fireFunction === 'login') {
      await this.firebase.login().then(() => {
        if (this.generalService.navBarExpanded) {
          this.toggleSnav();
        }
      });
    } else {
      await this.firebase.logout().then(() => {
        if (this.generalService.navBarExpanded) {
          this.toggleSnav();
        }
      });
    }
  }

  manual() {
    const googleIn = document.getElementsByName('googleIn');
    const googleOut = document.getElementsByName('googleOut');
    for (let i = 0; i < googleIn.length; i++) {
      googleIn[i].classList.add('slideIn');
      googleIn[i].classList.remove('slideOut');
    }
    for (let i = 0; i < googleOut.length; i++) {
      googleOut[i].classList.add('slideIn');
      googleOut[i].classList.remove('slideOut');
    }
  }

  componentChange() {
    if (this.pageLoaded) {
      // RESET SIDE NAV BAR
      const topNavItems = document.getElementsByName('topNav');
      const subNavItems = document.getElementsByName('subNav');
      for (let i = 0; i < topNavItems.length; i++) {
        topNavItems[i].classList.remove('topNavActive');
        topNavItems[i].classList.add('topNav');
      }
      for (let i = 0; i < subNavItems.length; i++) {
        subNavItems[i].classList.remove('subNavActive');
        subNavItems[i].classList.add('subNav');
      }
      // CHANGE TO MATCH NEW LOCATION
      let topNav: any;
      switch (this.router.url.split('/')[1]) {
        case ('AM'):
          topNav = document.getElementById('Asset Management');
          break;
        case ('Admin'):
          topNav = document.getElementById('Admin');
          break;
        default:
          topNav = null;
          break;
      }
      if (topNav != null) {
        topNav.classList.remove('topNav');
        topNav.classList.add('topNavActive');
        for (let i = 0; i < subNavItems.length; i++) {
          if (subNavItems[i].id === this.router.url.split('/')[2]) {
            subNavItems[i].classList.remove('subNav');
            subNavItems[i].classList.add('subNavActive');
            break;
          }
        }
      }
    }
  }
}
