import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ChangePasswordModel } from '../../_core/models/ChangePasswordModel';


@Injectable()
export class UserService {
  defaultProject: number = null;

  constructor(
    private httpclient: HttpClient) {

  }

  getUserName(): string {
    let user: any;
    if (localStorage.getItem('userdata')) {
      user = JSON.parse(localStorage.getItem('userdata'));
    } else {
      user = JSON.parse(sessionStorage.getItem('userdata'));
    }
    return user.UserName;
  }

  storeUserData(userdata: any, slTF: boolean): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/User/GetUser?username=' + userdata);
  }

  getUserData() {
    let user;
    if (localStorage.getItem('userdata')) {
      user = JSON.parse(localStorage.getItem('userdata'));
    } else {
      user = JSON.parse(sessionStorage.getItem('userdata'));
    }
    return user;
  }

  getRole() {
    let role;
    if (localStorage.getItem('role')) {
      role = JSON.parse(localStorage.getItem('role'));
    } else {
      role = JSON.parse(sessionStorage.getItem('role'));
    }
    return role;
  }

  hasRole(roles: string[]): boolean {
    const userRoles: string[] = sessionStorage.getItem('role') ?
      JSON.parse(sessionStorage.getItem('role')) :
      JSON.parse(localStorage.getItem('role'));
    roles.forEach(element => {
      if (userRoles.includes(element)) {
        return true;
      }
    });
    return false;
  }

  getAssignedOrg(): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/User/getAssignedOrg');
  }
  getOwnerOrgs(): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/User/GetOwnerOrgs');
  }

  getUserByID(userID: string): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/User/getUserByID?Id=' + userID);
  }

  userChangePassword(changePasswordModel: ChangePasswordModel ) {
    return this.httpclient.post(environment.apiBaseURL + 'api/User/UserChangePassword', changePasswordModel);
  }

  getUserByEmail(userEmail: string): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/User/GetUserByEmail?email=' + userEmail);
  }

  UpdateProfile(AdminUpdateProfile): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/User/UpdateProfile', AdminUpdateProfile);
  }
  getOrgUsers(): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/User/GetOrgUsers');
  }
}
