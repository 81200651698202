import { Injectable } from '@angular/core';
import ODataContext from 'devextreme/data/odata/context';
import { AuthenticationService } from '../../_core/services/authentication.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class ODataService {
  context: ODataContext;

  constructor( private authService: AuthenticationService) {
    this.context = new ODataContext({
      url: environment.apiBaseURL + 'odata',
      version: 4,
      beforeSend: function(request) {
          request.headers['Authorization'] = 'Bearer ' + authService.token;
      },
      entities: {
        TM_Users_vw: {
          key: 'Id',
          keyType: 'String'
        },
        TM_TicketUsers_vw: {
          key: 'ID',
          keyType: 'Int32'
        },
        TM_TicketCategory_vw: {
          key: 'TicketCategoryID',
          keyType: 'Int32'
        },
        TM_TicketCategoryWithPrivate_vw: {
          key: 'TicketCategoryID',
          keyType: 'Int32'
        },
        TM_TicketAvailableUsers_vw: {
          key: 'ProjectID',
          keyType: 'Int32'
        },
        TM_TicketUnAssignedCategory_vw: {
          key: 'TicketCategoryID',
          keyType: 'Int32'
        },
        TM_EquipmentType_vw: {
          key: 'EquipmentID',
          keyType: 'Int32'
        },
        TM_PendingAssetReservations_vw: {
          key: 'ID',
          keyType: 'Int32'
        },
        TM_PendingLocationReservations_vw: {
          key: 'ID',
          keyType: 'Int32'
        },
        TM_AssignedCategories_vw: {
          key: 'ID',
          keyType: 'Int32'
        },
      }
    });
  }

  getContext(): any {
      return this.context;
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
