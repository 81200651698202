import { Injectable } from '@angular/core';

export interface NavItem {
  displayName: string;
  icon: boolean;
  iconName?: string;
  imgSrc?: string;
  route?: string;
  function?: string;
  permission: string;
  children?: NavItem[];
}

const NavItems: NavItem[] = [
  {
    displayName: 'Asset Management',
    icon: true,
    iconName: 'assignment',
    permission: 'Asset Management',
    children: [
      {
        displayName: 'Tickets',
        icon: true,
        iconName: 'view_list',
        route: '/AM/Tickets',
        permission: 'Asset Management'
      },
      {
        displayName: 'Contacts',
        icon: true,
        iconName: 'contacts',
        route: '/AM/Contacts',
        permission: 'Asset Management'
      },
      {
        displayName: 'Categories',
        icon: true,
        iconName: 'category',
        route: '/AM/Categories',
        permission: 'Asset Management'
      },
      {
        displayName: 'Facility Calendar',
        icon: true,
        iconName: 'calendar_today',
        route: '/AM/FacilityCalendar',
        permission: 'Asset Management'
      },
      {
        displayName: 'Assets',
        icon: true,
        iconName: 'developer_board',
        route: '/AM/Assets',
        permission: 'View Asset'
      },
      {
        displayName: 'Management',
        icon: true,
        iconName: 'warning',
        route: '/AM/Management',
        permission: 'Manage Tickets'
      },
    ]
  },
  {
    displayName: 'Admin',
    icon: true,
    iconName: 'vpn_key',
    permission: 'Admin',
    children: [
      {
        displayName: 'Users',
        icon: true,
        iconName: 'supervised_user_circle',
        route: '/Admin/Users',
        permission: 'Admin'
      },
      {
        displayName: 'Facilities',
        icon: true,
        iconName: 'work',
        route: '/Admin/Facilities',
        permission: 'Admin'
      },
      {
        displayName: 'Organizations',
        icon: true,
        iconName: 'business',
        route: '/Admin/Organizations',
        permission: 'Admin'
      },
      // {
      //   displayName: 'Audit', // NOTE: TEMP REMOVED
      //   iconName: 'report',
      //   route: '/Admin/Audit',
      //   permission: "Admin"
      // }
    ]
  },
  // {
  //   displayName: 'Google',
  //   icon: false,
  //   iconName: 'business',
  //   imgSrc: 'assets/images/Google-icon-gray.png',
  //   permission: "Asset Management",
  //   children: [
  //     {
  //       displayName: 'Login',
  //       icon: true,
  //       iconName: 'lock_open',
  //       function: 'firebase.login()',
  //       permission: "Asset Management"
  //     },
  //     {
  //       displayName: 'Logout',
  //       icon: true,
  //       iconName: 'lock',
  //       function: 'firebase.logout()',
  //       permission: "Asset Management"
  //     }
  //   ]
  // }
];

@Injectable()
export class MenuItems {
  getNavItems(): NavItem[] {
    return NavItems;
  }
}
