import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ResetPasswordModel } from '../_core/models/ResetPasswordModel';

@Injectable()
export class SessionService {
  constructor(
    private httpclient: HttpClient) { }

  login(username: string, password: string): Observable<any> {
    // const body = 'grant_type=password&username=' + username + '&password=' + password;
    // const httpOptions = {
    //   headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    // };
    // return this.httpclient.post(environment.apiBaseURL + 'Token', body, httpOptions);
    let credentials = "grant_type=password"
    + "&UserName=" + username
    + "&Password=" + password;
  let httpOptions =
  {
    headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
  };
  return this.httpclient.post(environment.apiBaseURL + 'Token', credentials, httpOptions)
  }

  forgotPassword(un): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Email/ForgotPassword?email=' + un);
  }

  confirmAccount(resetPasswordData: ResetPasswordModel): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/Email/ResetPassword', resetPasswordData);
  }

  resetPassword(resetPasswordData: ResetPasswordModel): Observable<any> {
    return this.httpclient.post(environment.apiBaseURL + 'api/Email/ResetPassword', resetPasswordData);
  }
}
