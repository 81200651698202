import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  arrayUplaod=[];
  public isLoading = new BehaviorSubject(false);
  public isUploading = new BehaviorSubject(false);
  public addtoQueue (file){
    this.arrayUplaod.push(file);
    this.newUpload();
  }
  public removeQueue (){
    this.arrayUplaod.pop();
    this.newUpload();
  }
  public newUpload(){
    if (this.arrayUplaod.length === 0){
      this.isUploading.next(false);
    }
    else{
      this.isUploading.next(true);
    }
  }
  //public isUploading = new BehaviorSubject(false);
  constructor() { }
}
