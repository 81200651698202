import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable()
export class DashboardService {


  constructor(
    private httpclient: HttpClient) {
  }

  getUserDashboard(): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Dashboard/getUserDashboard');
  }

  getProjectDashboard(): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Dashboard/getProjectDashboard');
  }

  getTicketPriorityByProject(ProjectID: number): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Dashboard/getTicketPriorityByProject?ProjectID=' + ProjectID);
  }

  getTicketStatusByProject(ProjectID: number): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Dashboard/getTicketStatusByProjectID?ProjectID=' + ProjectID);
  }

  getTicketAgeByProject(ProjectID: number): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Dashboard/getTicketAgeByProject?ProjectID=' + ProjectID);
  }
  getDashboard(): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Dashboard/getDashboard');
  }

  getDashboardSummary(projectId: number): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Dashboard/getDashboard?projectId=' + projectId);
  }

}
