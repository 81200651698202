import { Injectable, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})

export class AuthenticationService {
  private authToken: string;
  private rUrl: string ;
  private redirectCode: string;
  @Output() hasTokenRefreshed = new EventEmitter<any>();

  constructor(
    private router: Router,
    private toastr: ToastrService) {

  }

  // Used to get the auth token.  Should only be called from the authentication interceptor.
  get token(): string {
    if (!this.authToken) {
      if (localStorage.getItem('uat')) {
        this.authToken = localStorage.getItem('uat');
      } else {
        this.authToken = sessionStorage.getItem('uat');
      }
    }
    return this.authToken;
  }

  get redirectURL(): string {
    console.log('redirect to', this.rUrl);
    // return this.rUrl;
  return  window.location.href = ('/u/Login?returnTo=' + this.rUrl);
  }

  storeRedirectURL(url: string): void {
    this.rUrl = url;
  }

  // Called when a call comes back with a 401, should return the user to the login page.
  // 6-May-2019: https://trello.com/c/PqpUmdz2/130-user-denied-access
  //             Need to redirect to 401 Page, instead of forcing logout.
  handleUnauthorized() {
    this.setRedirect('401');
    this.router.navigate(['/Dashboard']);
    this.toastr.error('You are not authorized to perform this action.', 'Unauthorized request');
  }

  // Called when a user has no stored credentials on the Login page.
  handleUnauthenticated() {
    this.clearAuth();
    this.router.navigate(['/u/Login']);
  }

  setRedirect(string){
    this.redirectCode = string;
  }

  checkRedirect(){
    var x = this.redirectCode;
    this.redirectCode = null;
    return x;
  };

  // Used to update the token, should generally only be called from the login service.
  storeToken(token: string, slTF: boolean, role: string): void {
    this.authToken = token;
      console.log('var',slTF)
    // if (slTF === true) {

      localStorage.setItem('uat', token);
      localStorage.setItem('role', role);
    // } else {
    //   sessionStorage.setItem('uat', token);
    //   sessionStorage.setItem('role', role);
    // }
    this.hasTokenRefreshed.emit(true);
  }

  // Check to verify the user is logged in
  isLoggedIn(): boolean {
    return this.token != null;
  }

  // Logs the user out and returns them to the login screen
  logout() {
    this.clearAuth();
    // use the referrer url as a url parameter
    window.location.href = ('/u/Login?returnTo=' + this.rUrl);
  }

  // Clears all credentials so that there is no leftover user information
  private clearAuth() {
    this.authToken = null;
    localStorage.clear();
    sessionStorage.clear();
  }
}
