import { Injectable } from '@angular/core';


@Injectable()
export class GeneralService {
  private paramArray: any[] = [null];
  public navBarExpanded = false;
  public mobileTF = false;
  public pageSize: {
    height: number,
    width: number,
    landscape: boolean
  } = {
      height: window.innerHeight,
      width: window.innerWidth,
      landscape: window.innerWidth > window.innerHeight ? true : false
    };

  constructor() {
    // console.log(this.pageSize);
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobi/i.test(navigator.userAgent)) {
      this.mobileTF = true;
    } else {
      this.mobileTF = false;
    }
  }

  checkMobile() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobi/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  }

  search(key, value, array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return array[i];
      }
    }
    return false;
  }

  convertDatetimeToLocal(datetime) {
    const localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const rawDT = new Date(datetime);
    const newDT = rawDT.toLocaleString('en-GB',
      {
        timeZone: localTZ,
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      }) + ' @ ' +
      rawDT.toLocaleString('en-US',
        {
          timeZone: localTZ,
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        });
    return newDT;
  }

  roleMatch(allowedRoles): boolean {
    let isMatch = false;
    const userRoles: string[] = sessionStorage.getItem('role') ?
      JSON.parse(sessionStorage.getItem('role')) :
      JSON.parse(localStorage.getItem('role'));
    userRoles.forEach(element => {
      if (element === 'SysAdmin' || element === 'Root') {
        isMatch = true;
      }
    });
    if (!isMatch) {
      allowedRoles.forEach(element => {
        if (userRoles.indexOf(element) > -1) {
          isMatch = true;
          return isMatch;
        }
      });
    }
    return isMatch;
  }

  addParams(params: any[]) {
    this.paramArray.pop();
    params.forEach(element => {
      this.paramArray.push(element);
    });
  }

  addTicketsRedirectParams(params: any[]) {
    this.paramArray = params;
  }

  getParams() {
    const params = this.paramArray;
    this.paramArray = [null];
    return params;
  }
}
