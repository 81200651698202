import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { LayoutsModule } from './layouts/layouts.module';
import { AuthGuard } from './_core/route-guards/auth.guard';
import { ToastrModule } from 'ngx-toastr';
import { CoreModule } from './_core/core.module';
import { DragulaModule } from 'ng2-dragula';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { FileSaverModule } from 'ngx-filesaver';
import { LoaderService } from './loader.service';
import { LoaderInterceptor } from './loader.interceptor';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DxDataGridModule } from 'devextreme-angular';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    AppRoutingModule,
    LayoutsModule,
    DxDataGridModule,
    CoreModule.forRoot(), // load everything in core module as single tons! no need for providing services!
    ToastrModule.forRoot({ // loading toaster module to be used everywhere!
      // Global
      maxOpened: 1,
      autoDismiss: true,
      newestOnTop: true,
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      iconClasses: {
        error: 'toast-error',
        info: 'toast-info',
        success: 'toast-success',
        warning: 'toast-warning',
      },
      // Individual defaults
      closeButton: false,
      disableTimeOut: false,
      timeOut: 5000,
      extendedTimeOut: 1000,
      enableHtml: false,
      progressBar: true,
      toastClass: 'toast',
      positionClass: 'toast-bottom-center', // toast-(top/bottom)-(left/center/right/full-width) || inline
      titleClass: 'toast-title',
      messageClass: 'toast-message',
      easing: 'ease-in',
      easeTime: 500,
      tapToDismiss: true,
      onActivateTick: false,
      progressAnimation: 'decreasing',
    }),
    DragulaModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    FileSaverModule,
    InfiniteScrollModule
  ],
  providers: [AuthGuard, LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }],
  bootstrap: [AppComponent,]
})
export class AppModule { }
