import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { UploaderEvent } from 'src/app/_core/models/AttachmentsModel';
import { LoaderService } from '../../loader.service';

@Injectable()
export class UploaderService {
    
  @Output() uploaded = new EventEmitter<UploaderEvent>();
    constructor(
        private httpclient:HttpClient,
        private toastr : ToastrService, 
        public loaderService: LoaderService){
    }

    processFiles(id:any, files: any[], attachmentSource:any = null){
        if(attachmentSource == null){
          console.error('forgot to input attachment source to the fileUplaoder');
          //todo replace with toaster.
        }
        console.log(id);
        let fileRepresentationArray = [];
        for( let i = 0; i<files.length; i++){
          let currentFile:any  = files[i].file;
          let fileRep = {name: currentFile.name, size: currentFile.size};
          fileRepresentationArray.push(fileRep);
        }
        //* get the new objects from API to upload to S3
        this.getSignedURL(id,fileRepresentationArray,attachmentSource).subscribe(
          (res:any) =>{
            console.log('uploading to s3')
            this.uploadToS3(res.filesToUpload,files, attachmentSource);
          }
          , (err:any) =>{
            console.log(err);
          });
     
      }
      getSignedURL(id:any, fileObjects:any, type:string ){
        //let data = {filesToAttach: fileObjects};
        let data = {filesToAttach: fileObjects, TicketID: null, AssetID: null, AssetType:null};
        if(type ==="Ticket"){
          data.TicketID = id;
        }
        else if(type ==="Location"){
          data.AssetID = id;
          data.AssetType = 'Location';
        }
         else if(type ==="Equipment"){
         data.AssetID= id;
         data.AssetType = 'Equipment';
         }
     
        console.log(data);
        return this.httpclient.post( environment.apiBaseURL + 'api/aws/LargeUploadAttachment', data);
      }
    
      //* prepare files for upload
      uploadToS3(filesToUploadArray: any[],files: any[],attachmentSource: string){
        console.log(filesToUploadArray);
        console.log(files);
        for(let i:any = 0; i < filesToUploadArray.length;i++){
          let uploadFile : any;

        //notify users
        if(filesToUploadArray !== undefined|| filesToUploadArray !== []){
          this.toastr.info('Attachment upload started, Don\'t Leave the Site','File Uploader');
        }
        //filter files where it matches the name 
          for(let f:any = 0; f<files.length; f++){
            if(filesToUploadArray[i].name === files[f].file.name){
              console.log(filesToUploadArray[i].name);
              console.log(files[f].file.name);
              uploadFile=files[f].file;
            } 
          }
          console.log(uploadFile);
          //* do request to S3
          if(uploadFile === null && uploadFile !== undefined){
            if(filesToUploadArray[i].signedURL === null){
              console.log( filesToUploadArray[i].name +' is Too Large to Upload');
              //todo replace with toastr
              this.toastr.error(filesToUploadArray[i].name +' is Too Large to Upload', 'File Uploader');
    
            }
          }else{
            let headers = new HttpHeaders();
            headers.delete('Authorization');
            this.httpclient.put(
              filesToUploadArray[i].signedURL,
              uploadFile.rawFile,
              {headers:
                {
                'fileKey': filesToUploadArray[i].attachmentGuid,
                'Content-Type': 'application/octet-stream'
                }
               }
              )
            .subscribe(
              (res:any)=>{  //* succesful upload
                console.log(res);
                this.reportUpload(filesToUploadArray[i].attachmentGuid,true, attachmentSource) ; 
                console.log('Upload for: \"'+uploadFile.name+'\" went Successfully!'); 
                this.toastr.success('Upload for: \"'+uploadFile.name+'\" went Successfully!','File Uploader');
                //todo add closing uploader widget here
               
                
              //todo replace with toastr
    
              }
            ,(err:any)=>{ //*failure when uploading.
              console.log(err);
              this.reportUpload(filesToUploadArray[i].attachmentGuid,false, attachmentSource);  
              console.log('Upload for: \"'+uploadFile.name+'\" Failed.') 
              this.toastr.error('Upload for: \"'+uploadFile.name+'\" Failed.', 'File Uploader');
              //todo replace with toastr
            });
          }
        }
      }
    
      reportUpload(guid: any, state: boolean, type: string = 'Ticket'){  //TODO include the type of the attachment to all the attachments.
        this.httpclient.post(
          environment.apiBaseURL +"api/aws/UploadSuccess"
          ,{
            attachmentGuid: guid,
            didItUploadTF:state,
            type: type
          }
        ).subscribe(res=>{
          let event: UploaderEvent = new UploaderEvent();
          event.Result = state;
          this.uploaded.emit(event);
          this.loaderService.removeQueue();

          
        });
      }
    
}
