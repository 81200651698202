import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable()
export class ReportingService {
  defaultProject: number = null;

  constructor(
    private httpclient: HttpClient) {

  }

  getRPTByAsset(projectID: number): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Reporting/GetRPTByAsset?TM_ProjectID=' + projectID);
  }

  getRPTByLocation(projectID: number): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Reporting/GetRPTByLocation?TM_ProjectID=' + projectID);
  }

  getRPTByCategory(projectID: number): Observable<any> {
    return this.httpclient.get(environment.apiBaseURL + 'api/Reporting/GetRPTByCategory?TM_ProjectID=' + projectID);
  }
}
