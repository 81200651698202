// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  clientBaseURL: 'http://localhost:4200',
  googleRedirectURL: 'http://localhost:4200',
  // apiBaseURL: 'http://api.owner-insite.com/',
  // apiBaseURL: 'https://dev-as-operations-v2-api.azurewebsites.net/',
  apiBaseURL: 'http://localhost:59137/',
  firebase: {
    apiKey: "AIzaSyAAsoGGsMd2mibhuV1UNWbipqrDLI4LKBY",
    authDomain: "am-owner-insite.firebaseapp.com",
    databaseURL: "https://am-owner-insite.firebaseio.com",
    projectId: "am-owner-insite",
    storageBucket: "am-owner-insite.appspot.com",
    messagingSenderId: "182621452859",
    appId: "1:182621452859:web:0364bc82707b9f982bfa1e",
    measurementId: "G-YPF2HRL902"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
