import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthenticationService
  ) { }

  // Upon recieving the request back, checks for unauthorized status, and if it has it, handles it according to the auth service
  handleError = (err: any) => {
    if (err instanceof HttpErrorResponse) {
      if (err.status === 401 || err.status === 403) {
        // 401 unauthorized response so call the auth service to handle it properly.
        this.authService.handleUnauthorized();
        return new Observable();
      } else {
        return of(err.message || err.statusText || err.error || err);
      }
    }
  }

  // Appends the authorization token, so long as there is one, before sending the request.
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.authService.isLoggedIn()) {
      if (req.url.search('s3.amazonaws.com/OisUploads/') && req.method === 'PUT'){
        console.log(req.url);
        console.log('goes to amazon')
        return next.handle(req).pipe(
          tap(() => { }, this.handleError)
        );
      }else{
         // If there is an authorization token, add the Authorizaiton header and the token
        const modifiedReq = req.clone({
        setHeaders: {
            Authorization: 'Bearer ' + this.authService.token
          }
        });
        return next.handle(modifiedReq).pipe(
          tap(() => { }, this.handleError)
        );
      } 
    } else {// Not logged in, so no token can be added
      return next.handle(req).pipe(
        tap(() => { }, this.handleError)
      );
    }

  }
}
